import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@material-ui/core';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import { Star as StarIcon } from '@material-ui/icons';
import { disasterList } from './disasterList';
import 'react-vertical-timeline-component/style.min.css';
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(6, 0, 3),
    color: '#000',
  },
  rocket: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
  imageIcon: {
    height: '72%',
    marginLeft: 15,
    marginTop: 6,
  },
  iconRoot: {
    textAlign: 'center',
  },
  date: { color: '#000' },
}));

export default function DisasterTimeline() {
  const classes = useStyles();
  return (
    <VerticalTimeline>
      {disasterList.length > 0 &&
        disasterList
          .sort(
            (a, b) =>
              new Date(b.incidentDate).getTime() -
              new Date(a.incidentDate).getTime()
          )
          .map((disaster) => (
            <VerticalTimelineElement
              key={disaster.incidentDate}
              className='vertical-timeline-element--work'
              contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              contentArrowStyle={{
                borderRight: '7px solid  rgb(33, 150, 243)',
              }}
              date={disaster.incidentDate}
              dateClassName={classes.date}
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              icon={
                <Icon classes={{ root: classes.iconRoot }}>
                  <img
                    className={classes.imageIcon}
                    src='/rocket.svg'
                    color='primary'
                    alt='rocket icon'
                  />
                </Icon>
              }
            >
              <h3 className='vertical-timeline-element-title'>
                {disaster.incident}
              </h3>
              <h4 className='vertical-timeline-element-subtitle'>
                {disaster.mission}
              </h4>
              <h6 className='vertical-timeline-element-subtitle'>
                {disaster.location}
              </h6>
              <p>
                Fatalities:{' '}
                {disaster.fatalityList.length < 1
                  ? !disaster.fatalityCount
                    ? 0
                    : disaster.fatalityCount
                  : disaster.fatalityList.join(', ')}
              </p>
              <p>{disaster.description}</p>
            </VerticalTimelineElement>
          ))}
      <VerticalTimelineElement
        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
        icon={<StarIcon />}
      />
    </VerticalTimeline>
  );
}
