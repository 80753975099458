export const disasterList = [
  {
    incidentDate: "1967-04-24",
    incident: "Parachute failure",
    mission: "Soyuz 1",
    location: "",
    fatalityCount: "",
    fatalityList: ["Vladimir Komarov"],
    description:
      "The one-day mission was plagued by a series of mishaps with the new spacecraft type, culminating with its parachute not opening properly after atmospheric reentry. Komarov was killed when the capsule hit the ground at high speed. The Soyuz 1 crash site coordinates are 51°21′41″N 59°33′44″E﻿ / ﻿51.3615°N 59.5622°E, 3 kilometers (1.9 mi) west of Karabutak, Province of Orenburg in the Russian Federation, about 275 kilometers (171 mi) east-southeast of Orenburg. In a small park on the side of the road is a memorial monument: a black column with a bust of Komarov at the top."
  },
  {
    incidentDate: "1971-06-30",
    incident: "Decompression in space",
    mission: "Soyuz 11",
    location: "",
    fatalityCount: "",
    fatalityList: ["Georgy Dobrovolsky", "Viktor Patsayev", "Vladislav Volkov"],
    description:
      "The crew of Soyuz 11 were killed after undocking from space station Salyut 1 after a three-week stay. A cabin vent valve construction defect caused it to open at service module separation. The recovery team found the crew dead. These three are (as of 2019) the only human fatalities in space (above 100 kilometers (330,000 ft)). The Soyuz 11 landing coordinates are 47°21′24″N 70°07′17″E﻿ / ﻿47.35663°N 70.12142°E, 90 kilometers (56 mi) southwest of Karazhal, Karagandy, Kazakhstan, and about 550 kilometers (340 mi) northeast of Baikonur, in open flat country far from any populated area. In a small circular fenced area at the site is a memorial monument in the form of a three-sided metallic column. Near the top of the column on each side is the engraved image of the face of a crew member set into a stylized triangle."
  },
  {
    incidentDate: "2003-02-01",
    incident:
      "Vehicle disintegration on re-entry – Space Shuttle Columbia disaster",
    mission: "STS-107",
    location: "",
    fatalityCount: "",
    fatalityList: [
      "Rick D. Husband",
      "William C. McCool",
      "Michael P. Anderson",
      "David M. Brown",
      "Kalpana Chawla",
      "Laurel Clark",
      "Ilan Ramon"
    ],
    description:
      "The Space Shuttle Columbia was lost as it returned from a two-week mission, STS-107. Damage to the shuttle's thermal protection system (TPS) led to structural failure of the shuttle's left wing and the spacecraft ultimately broke apart during reentry at an altitude of under 65 km. Investigation revealed damage to the reinforced carbon-carbon leading edge wing panel resulted from the impact of a piece of foam insulation that broke away from the external tank during the launch. The vehicle broke up over the southwestern United States and fell in fragments over eastern Texas and central Louisiana."
  },
  {
    incidentDate: "1967-11-15",
    incident: "Control failure",
    mission: "X-15 Flight 3-65-97",
    location: "",
    fatalityCount: "",
    fatalityList: ["Michael J. Adams"],
    description:
      "During X-15 Flight 191, Adams' seventh flight, the plane had an electrical problem followed by control problems at the apogee of its flight. The pilot may also have become disoriented. During reentry from a 266,000 ft (50.4 mile, 81.1 km) apogee, the X-15 yawed and went into a spin at Mach 5. The pilot recovered, but went into a Mach 4.7 inverted dive. Excessive loading led to structural breakup at about 65,000 feet (19.8 km). Adams was posthumously awarded astronaut wings, as his flight had passed an altitude of 50 miles (80.5 km),"
  },
  {
    incidentDate: "1986-01-28",
    incident:
      "Launch booster failure, vehicle disintegration during launch – Space Shuttle Challenger disaster",
    mission: "STS-51-L",
    location: "",
    fatalityCount: "",
    fatalityList: [
      "Gregory Jarvis",
      "Christa McAuliffe",
      "Ronald McNair",
      "Ellison Onizuka",
      "Judith Resnik",
      "Michael J. Smith",
      "Dick Scobee"
    ],
    description:
      "The Space Shuttle Challenger was destroyed 73 seconds after lift-off on STS-51-L at an altitude of 15 kilometers (49,000 ft). The investigation found that cold weather conditions caused an O-ring seal to fail, allowing hot gases from the shuttle solid rocket booster (SRB) to impinge on the external propellant tank and booster strut. The strut and aft end of the tank failed, allowing the top of the SRB to rotate into the top of the tank. Challenger was thrown sideways into the Mach 1.8 windstream and broke up with the loss of all seven crew members. NASA investigators determined they may have survived the spacecraft disintegration, possibly unconscious from hypoxia; some tried to activate their emergency oxygen.  Any survivors of the breakup were killed, however, when the largely intact cockpit hit the water at 320 km/h (200 mph), about 32 km (20 miles) east of Cape Canaveral at 28.64 degrees north, 80.28 degrees west. About half of the vehicle's remains were never recovered, and fragments still washed ashore as long as a decade later, on the coast of Brevard County, Florida."
  },
  {
    incidentDate: "1961-03-23",
    incident: "Fire in altitude chamber",
    mission: "(Soviet Air Force Group 1)",
    location: "",
    fatalityCount: "",
    fatalityList: ["Valentin Bondarenko"],
    description:
      "First space-related fatality. During a 15-day endurance experiment in a low-pressure altitude chamber with at least 50% oxygen atmosphere, Vostok cosmonaut trainee Bondarenko dropped an alcohol-soaked cloth onto an electric hotplate. He suffered third-degree burns over most of his body and face, and died in a hospital 16 hours later."
  },
  {
    incidentDate: "1964-10-31",
    incident: "Training jet crash",
    mission: "(NASA Astronaut Group 3)",
    location: "",
    fatalityCount: "",
    fatalityList: ["Theodore Freeman"],
    description:
      "Before being selected for a Gemini crew, Freeman was flying a T-38 jet trainer on landing approach to Ellington AFB near Houston, TX, when a goose struck the left side of the cockpit canopy. Shards of Plexiglas entered the engine intake and caused both engines to flame out. Freeman ejected too close to the ground for his parachute to open properly."
  },
  {
    incidentDate: "1966-02-28",
    incident: "Training jet crash",
    mission: "Gemini 9",
    location: "",
    fatalityCount: "",
    fatalityList: ["Elliot See", "Charles Bassett"],
    description:
      "See and Bassett attempted to land their T-38 at Lambert Field in St. Louis, Missouri in bad weather, and crashed into the adjacent McDonnell Aircraft factory, where they were going for simulator training for their Gemini 9 flight."
  },
  {
    incidentDate: "1967-01-27",
    incident: "Fire during spacecraft test",
    mission: "Apollo 1",
    location: "",
    fatalityCount: "",
    fatalityList: ['Virgil "Gus" Grissom', "Ed White", "Roger B. Chaffee"],
    description:
      'An electrical fire in the cabin spread quickly in the pure oxygen atmosphere and claimed the lives of all three Apollo 1 crew members during a "plugs-out" test in preparation for their planned February 21 launch.'
  },
  {
    incidentDate: "1967-10-05",
    incident: "Training jet crash",
    mission: "(Apollo)",
    location: "",
    fatalityCount: "",
    fatalityList: ["Clifton C. Williams"],
    description:
      "Williams, flying alone in a T-38 jet from Cape Kennedy, Florida to Houston, Texas, crashed due to an aileron control mechanical failure, about 15 miles (24 km) north of Tallahassee, Florida. Williams ejected too low for the parachute to open properly. Williams had been selected as lunar module pilot on an Apollo crew with commander Pete Conrad and command module pilot Richard Gordon."
  },
  {
    incidentDate: "1967-12-08",
    incident: "Training jet crash",
    mission: "(Manned Orbiting Laboratory)",
    location: "",
    fatalityCount: "",
    fatalityList: ["Robert Henry Lawrence Jr."],
    description:
      "The first African-American astronaut, selected for the Air Force Manned Orbiting Laboratory program, was killed when his F-104 Starfighter jet crashed at Edwards Air Force Base, California, while practicing a series of high speed, quick descent landings with Major Harvey Royer as pilot in command. Both crewmen ejected; Royer survived with injuries, but Lawrence, the instructor pilot, was found in his ejection seat, parachute not fully deployed."
  },
  {
    incidentDate: "1993-07-11",
    incident: "Drowned during water recovery training",
    mission: "(Soviet Air Force Cosmonaut Training Group 11)",
    location: "",
    fatalityCount: "",
    fatalityList: ["Sergei Vozovikov"],
    description:
      "Sergei Yuriyevich Vozovikov was a member of the Soviet Air Force Cosmonaut Training Group 11. His Cosmonaut training was from 1 October 1991 to 6 March 1992. He drowned 11 July 1993 during water recovery training in the Black Sea, near Anapa, Russia."
  },
  {
    incidentDate: "2014-10-31",
    incident: "Spaceplane crash during test flight",
    mission: "VSS Enterprise PF04",
    location: "",
    fatalityCount: "",
    fatalityList: ["Michael Alsbury"],
    description:
      "Michael Alsbury was killed and Peter Siebold was seriously injured when SpaceShipTwo VSS Enterprise disintegrated during a powered atmospheric test flight over California due to premature deployment of the feathering system."
  },
  {
    incidentDate: "1961-04-12",
    incident: "Separation failure",
    mission: "Vostok 1",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "After retrofire, the Vostok service module unexpectedly remained attached to the reentry module by a bundle of wires. The two halves of the craft were supposed to separate ten seconds after retrofire. But they did not separate until 10 minutes after retrofire, when the wire bundle finally burned through. The spacecraft went into wild gyrations at the beginning of reentry, before the wires burned through and the reentry module settled into the proper reentry attitude."
  },
  {
    incidentDate: "1961-07-21",
    incident: "Landing capsule sank in water",
    mission: "Mercury-Redstone 4",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "After splashdown in the Atlantic Ocean, the hatch malfunctioned and blew, filling the capsule with water and almost drowning Gus Grissom, who managed to escape before it sank. Grissom then had to deal with a spacesuit that was rapidly filling with water, but managed to get into the helicopter's retrieval collar and was lifted to safety. The spacecraft was recovered in 1999, having settled 300 nmi (560 km; 350 mi) southeast of Cape Canaveral in 15,000 ft (4,600 m) of seawater. An unexploded SOFAR bomb, designed for sound fixing and ranging in case the craft sank, had failed and had to be dealt with when it was recovered from the ocean floor in 1999."
  },
  {
    incidentDate: "1965-03-18",
    incident: "Spacesuit or airlock design fault",
    mission: "Voskhod 2",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "The mission featured the world's first spacewalk, by Alexei Leonov. After his twelve minutes outside, Leonov's spacesuit inflated in the vacuum to the point where he could not reenter the airlock. He opened a valve to allow some of the suit's pressure to bleed off, and was barely able to get back inside the capsule after suffering side effects of the bends. Because the spacecraft was so cramped, the crew could not keep to their reentry schedule and landed 386 km off course in deep forest. They spent a night sheltering in the capsule from the cold, and a second night in a temporary hut built by rescuers before skiing with them to a clearing where a helicopter flew them to Perm."
  },
  {
    incidentDate: "1965-12-12",
    incident: "Engine shutdown at launch",
    mission: "Gemini 6A",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "The first on-pad shutdown in the US Manned Program. Gemini 7 orbiting 185 miles (298 km) directly over Missile Row witnessed the event and reported they could clearly see the momentary exhaust plume before shutdown."
  },
  {
    incidentDate: "1966-03-17",
    incident: "Equipment failure",
    mission: "Gemini 8",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "A maneuvering thruster refused to shut down and put their capsule into an uncontrolled spin. After the Gemini spun up to one revolution per second, Neil Armstrong regained control by switching from the main attitude control system to the reentry system.  Mission rules required a landing as soon as possible once the reentry thrusters were used, causing an early end to the flight."
  },
  {
    incidentDate: "1969-01-18",
    incident: "Separation failure",
    mission: "Soyuz 5",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "Harrowing reentry and landing when the capsule's service module initially refused to separate, causing the spacecraft to begin reentry faced the wrong way. The service module broke away before the capsule would have been destroyed, and so it made a rough but survivable landing far off course in the Ural mountains."
  },
  {
    incidentDate: "1969-11-14",
    incident: "Struck twice by lightning during launch",
    mission: "Apollo 12",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "Two lightning strikes during launch. The first strike, at 36 seconds after liftoff, knocked the three fuel cells offline and the craft switched to battery power automatically. The second strike, at 52 seconds after liftoff, knocked the onboard guidance platform offline. Four temperature sensors on the outside of the Lunar Module were burnt out and four measuring devices in the reaction control system failed temporarily. Fuel cell power was restored about four minutes later. The astronauts spent additional time in Earth orbit to make sure the spacecraft was functional before firing their S-IVB third stage engine and departing for the Moon."
  },
  {
    incidentDate: "1969-11-24",
    incident: "Struck by camera during splashdown",
    mission: "Apollo 12",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "Astronaut Alan Bean was struck above the right eyebrow by a 16mm movie camera when the spacecraft splashed down in the ocean. The camera broke free from its storage place. Bean suffered a concussion, and a 1.25 cm cut above the eyebrow that required stitches."
  },
  {
    incidentDate: "1970-04-11",
    incident: "Premature engine shutdown",
    mission: "Apollo 13",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "During launch, the Saturn V second stage experienced a premature shutdown on one of its five engines. The center engine shut down two minutes early. The remaining engines on the second and third stages were burned a total of 34 seconds longer to compensate. It was later determined that the shutdown was caused by pogo oscillation of the engine. Parking orbit and translunar injection were successfully achieved."
  },
  {
    incidentDate: "1970-04-13",
    incident: "Equipment failure",
    mission: "Apollo 13",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      'The crew came home safely after a violent rupture of a liquid oxygen tank deprived the Service Module of its ability to produce electrical power, crippling their spacecraft en route to the Moon. They survived the loss of use of their command ship by relying on the Lunar Module as a "life boat" to provide life support and power for the trip home.'
  },
  {
    incidentDate: "1971-08-07",
    incident: "One of three main parachutes failed",
    mission: "Apollo 15",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "During descent, the three main parachutes opened successfully. However, when the remaining reaction control system fuel was jettisoned, one parachute was damaged by the discarded fuel causing it to collapse. Spacecraft and crew still splashed down safely, at a slightly higher than normal velocity, on the two remaining main parachutes. If a second parachute had failed, the spacecraft would probably have been crushed on impact with the ocean, according to a NASA official."
  },
  {
    incidentDate: "1975-04-05",
    incident: "Separation failure",
    mission: "Soyuz 18a",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "The mission nearly ended in disaster when the rocket suffered a second-stage separation failure during launch. This also interrupted the craft's attitude, causing the vehicle to accelerate towards the Earth and triggering an emergency reentry sequence. Due to the downward acceleration, the crew experienced an acceleration of 21.3 g rather than the nominal 15 g for an abort. Upon landing, the vehicle rolled down a hill and stopped just short of a high cliff. The crew survived, but Lazarev, the mission commander, suffered internal injuries due to the severe G-forces and was never able to fly again."
  },
  {
    incidentDate: "1975-07-24",
    incident: "Chemical poisoning",
    mission: "Apollo-Soyuz Test Project",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "During final descent and parachute deployment, the U.S. crew were exposed to 300 µL/L of toxic nitrogen tetroxide (Reaction Control System oxidizer) fumes venting from the spacecraft and reentering a cabin air intake, because a switch was left in the wrong position. 400µL/L is fatal. Vance Brand lost consciousness for a short time. The crew members suffered from burning sensations of their eyes, faces, noses, throats and lungs. Thomas Stafford quickly broke out emergency oxygen masks and put one on Brand and gave one to Deke Slayton. The crew were exposed to the toxic fumes from 24,000 ft (7.3 km) down to landing. About an hour after landing the crew developed chemical-induced pneumonia and their lungs had edema. They experienced shortness of breath and were hospitalized in Hawaii. The crew spent five days in the hospital, followed by a week of observation in semi-isolation. By July 30, their chest X-rays appeared to return to normal except for Slayton; he was diagnosed with a benign lesion, unrelated to the gas exposure, which was later removed."
  },
  {
    incidentDate: "1976-10-16",
    incident: "Landing capsule sank in water",
    mission: "Soyuz 23",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "The capsule broke through the surface of a frozen lake and was dragged underwater by its parachute. The crew was saved after a very difficult rescue operation."
  },
  {
    incidentDate: "1979-04-12",
    incident: "Engine malfunction",
    mission: "Soyuz 33",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "Engine failure forced the mission to be aborted. It was the first-ever failure of a Soyuz engine during orbital operations. The crew, commander Nikolai Rukavishnikov and Bulgarian cosmonaut Georgi Ivanov, suffered a steep ballistic re-entry, but were safely recovered."
  },
  {
    incidentDate: "1981-04-12",
    incident:
      "SRB ignition shock wave overpressure reached design limits of orbiter structure",
    mission: "STS-1",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "During launch, the Solid Rocket Booster ignition shock wave overpressure was four times greater than expected (2.0 psi or 14 kPa measured vs 0.5 psi or 3.4 kPa predicted). Some of the aft structures on Space Shuttle Columbia reached their design limits (2.0 psi) from the overpressure. The overpressure bent four struts that supported two RCS fuel tanks in the nose of Columbia and the orbiter's locked body flap was pushed up and down 6 in (15 cm) by the shock wave. John Young and Robert Crippen in the crew cabin received a 3-G jolt from the shock wave. An improved water spray shock wave damping system had to be installed on the launch pad prior to launch."
  },
  {
    incidentDate: "1983-09-26",
    incident: "Fire in launch vehicle",
    mission: "Soyuz T-10-1",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "A fuel spillage before the planned liftoff caused the vehicle to be engulfed in flames. The crew was narrowly saved by the activation of their launch escape system, with the rocket exploding two seconds later."
  },
  {
    incidentDate: "1983-12-08",
    incident: "Leaked hydrazine fuel fire and explosion",
    mission: "STS-9",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "In the last two minutes of the mission, during Space Shuttle Columbia's final approach to the Edwards AFB runway, hydrazine fuel leaked onto hot surfaces of two of the three onboard auxiliary power units (APU) in the aft compartment of the shuttle and caught fire. About 15 minutes after landing, hydrazine fuel trapped in the APU control valves exploded, destroying the valves in both APUs. The fire also damaged nearby wiring. The fire stopped when the supply of leaked fuel was exhausted. All of this was discovered the next day when technicians removed an access panel and discovered the area blackened and scorched. It is believed that hydrazine leaked in orbit and froze, stopping the leak. After returning, the leak restarted and ignited when combined with oxygen from the atmosphere. There were no injuries during the incident."
  },
  {
    incidentDate: "1985-07-29",
    incident: "Space Shuttle in-flight engine failure",
    mission: "STS-51-F",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      'Five minutes, 45 seconds into ascent, one of three main engines aboard Challenger shut down prematurely due to a spurious high temperature reading. At about the same time, a second main engine almost shut down from a similar problem, but this was observed and inhibited by a fast acting flight controller. The failed SSME resulted in an Abort To Orbit (ATO) trajectory, whereby the shuttle achieves a lower than planned orbital altitude. Had the second engine failed within about 20 seconds of the first, a Transoceanic Abort Landing (TAL) abort might have been necessary. No bailout option existed until after mission STS-51-L, the Challenger disaster.  But even with that option, a bailout (a "contingency abort") would never be considered when an "intact abort" option exists, and after five minutes of normal flight it would always exist unless a serious flight control failure or some other major problem beyond engine shutdown occurred.'
  },
  {
    incidentDate: "1988-09-06",
    incident: "Sensor failure",
    mission: "Mir EP-3",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "At the end of the mission, Soviet cosmonaut Vladimir Lyakhov and Afghan cosmonaut Abdul Ahad Mohmand undocked from Mir in the spacecraft Soyuz TM-5. During descent they suffered a computer software problem combined with a sensor problem. The deorbit engine on the TM-5 spacecraft which was to propel them into atmospheric reentry, did not behave as expected. During an attempted burn, the computer shut off the engines prematurely, believing the spacecraft was out of alignment. Lyakhov determined that they were not, in fact, out of alignment, and asserted that the problem was caused by conflicting signals picked up by the alignment sensors caused by solar glare. With the problem apparently solved, two orbits later he restarted to deorbit engines. But the engines shut off again. The flight director decided that they would have to remain in orbit an extra day (a full revolution of the Earth), so they could determine what the problem was. During this time it was realised that during the second attempted engine burn, the computer had tried to execute the program which was used"
  },
  {
    incidentDate: "1988-12-06",
    incident: "Thermal tile damage",
    mission: "STS-27",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "Space Shuttle Atlantis' Thermal Protection System tiles sustained unusually severe damage during this flight. Ablative insulating material from the right-hand solid rocket booster nose cap had hit the orbiter about 85 seconds into the flight, as seen in footage of the ascent. The crew made an inspection of the Shuttle's impacted starboard side using the Shuttle's Canadarm robot arm, but the limited resolution and range of the cameras made it impossible to determine the full extent of the tile damage. Following reentry, more than 700 tiles were found to be damaged including one that was missing entirely. STS-27 was the most heavily damaged Shuttle to return to Earth safely."
  },
  {
    incidentDate: "1991-04-08",
    incident: "Spacesuit puncture",
    mission: "STS-37",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "During an extravehicular activity, a small rod (palm bar) in a glove of EV2 astronaut Jay Apt's extravehicular mobility unit punctured the suit. Somehow, the astronaut's hand conformed to the puncture and sealed it, preventing any detectable depressurization. During post-flight debriefings, Apt said after the second EVA, when he removed the gloves, his right hand index finger had an abrasion behind the knuckle. A postflight inspection of the right hand glove found the palm bar of the glove penetrating a restraint and glove bladder into the index finger side of the glove. NASA found air leakage with the bar in place was 3.8 SCCM, well within the specification of 8.0 SCCM. They said if the bar had come out of the hole, the leak still would not have been great enough to activate the secondary oxygen pack. The suit would, however, have shown a high oxygen rate indication."
  },
  {
    incidentDate: "1993-09-12",
    incident: "Explosive release device punctured cargo bay bulkhead",
    mission: "STS-51",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "While releasing the Advanced Communications Technology Satellite from the payload bay, both the primary and backup explosive release devices detonated. Only the primary device was supposed to have detonated. Large metal bands holding the satellite in place were ripped away, causing flying debris. The debris punctured the orbiter's payload bay bulkhead leading to the main engine compartment, damaging wiring trays and payload bay thermal insulation blankets. The puncture in the bulkhead was 3 mm by 13 mm in size. The crew was uninjured and the damage was not great enough to endanger the shuttle. The satellite was undamaged."
  },
  {
    incidentDate: "1995-05-18",
    incident: "Eye injury from Mir exercise equipment",
    mission: "Mir",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      'While exercising on the EO-18/NASA 1/Soyuz TM-21 mission, astronaut Norman E. Thagard suffered an eye injury. He was using an exercise device, doing deep knee bends, with elastic straps. One of the straps slipped off of his foot, flew up, and hit him in the eye. Later, even a small amount of light caused pain in his eye. He said using the eye was, "like looking at the world through gauze." An ophthalmologist at Mission Control-Moscow prescribed steroid drops and the eye healed.'
  },
  {
    incidentDate: "1997-02-23",
    incident: "Fire on board",
    mission: "Mir",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "There was a fire on board the Mir space station when a lithium perchlorate canister used to generate oxygen leaked.  The fire was extinguished after about 90 seconds, but smoke did not clear for several minutes."
  },
  {
    incidentDate: "1997-04-08",
    incident: "Fuel cell failure",
    mission: "STS-83",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "Fuel cell #2 aboard Space Shuttle Columbia unexpectedly failed on Day 4 in orbit, forcing an early end to the flight. The mission touched down safely, and the crew was reflown with the same mission plan on STS-94."
  },
  {
    incidentDate: "1997-06-25",
    incident: "Collision in space",
    mission: "Mir",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "At Mir, during a re-docking test with the Progress M-34 cargo freighter, the Progress freighter collided with the Spektr module and solar arrays of the Mir space station. This damaged the solar arrays and the collision punctured a hole in the Spektr module and the space station began depressurizing. The onboard crew of two Russians and one visiting NASA astronaut were able to close off the Spektr module from the rest of Mir after quickly cutting cables and hoses blocking the hatch closure."
  },
  {
    incidentDate: "1999-07-23",
    incident: "Main engine electrical short and hydrogen leak",
    mission: "STS-93",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "Five seconds after liftoff, an electrical short knocked out controllers for two shuttle main engines. The engines automatically switched to their backup controllers. Had a further short shut down two engines, Columbia would have ditched in the ocean, although the crew could have possibly bailed out. Concurrently a pin came loose inside one engine and ruptured a cooling line, allowing a hydrogen fuel leak. This caused premature fuel exhaustion, but the vehicle safely achieved a slightly lower orbit. Had the failure propagated further, a risky transatlantic or RTLS abort would have been required."
  },
  {
    incidentDate: "2001-02-10",
    incident: "Toxic ammonia leak during EVA",
    mission: "ISS/STS-98",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "During EVA 1 on the mission, NASA astronauts Robert L. Curbeam and Thomas D. Jones were connecting cooling lines on the International Space Station while working to install the Destiny Laboratory Module. A defective quick-disconnect valve allowed 5% of the ammonia cooling supply to escape into space. The escaping ammonia froze on the spacesuit of astronaut Curbeam as he struggled to close the valve. His helmet and suit were coated in ammonia crystals an inch thick. Mission Control instructed Curbeam to remain outside for an entire orbit to allow the Sun to evaporate the frozen ammonia from his spacesuit. When they returned to the airlock, the astronauts pressurized, vented and then repressurized the air lock to purge any remaining toxic ammonia. After they removed their spacesuits, the crew wore oxygen masks for another 20 minutes to allow life-support systems in the airlock to further filter the air. No injuries resulted from the incident."
  },
  {
    incidentDate: "2003-05-03",
    incident: "Ballistic reentry; injured shoulder",
    mission: "Soyuz TMA-1",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "The capsule had a malfunction during its return to Earth from the ISS Expedition 6 mission and performed a ballistic reentry. The crew was subjected to about 8 to 9 Gs during reentry. The capsule landed 500 km (310 mi) from the intended landing target. In addition, after landing the capsule was dragged about 15 metres (49 ft) by its parachute and ended up on its side in a hard landing. Astronaut Don Pettit injured his shoulder and was placed on a stretcher in a rescue helicopter and did not take part in post-landing ceremonies."
  },
  {
    incidentDate: "2004-09-29",
    incident: "Unplanned rolls during ascent",
    mission: "SpaceShipOne-16P",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "On suborbital flight 16P, the first of two flights that won the X-Prize for exceeding 100 km (62 mi) in altitude, astronaut Mike Melvill experienced 29 unplanned rolls during and after powered ascent. The rolls began at 50 seconds into the engine burn. The burn was stopped 11 seconds early after burning a total of 76 seconds. After engine cutoff, the craft continued rolling while coasting to apogee. The roll was finally brought under control after apogee using the craft's reaction jets. SpaceShipOne landed safely and Mike Melvill was uninjured."
  },
  {
    incidentDate: "2008-04-19",
    incident: "Separation failure",
    mission: "Soyuz TMA-11",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "Reentry mishap similar to that suffered by Soyuz 5 in 1969. The service module failed to completely separate from the reentry vehicle and caused it to face the wrong way during the early portion of aerobraking. As with Soyuz 5, the service module eventually separated and the reentry vehicle completed a rough but survivable landing. Following the Russian news agency Interfax's report, this was widely reported as life-threatening while NASA urged caution pending an investigation of the vehicle. South Korean astronaut Yi So-Yeon was hospitalized after her return to South Korea due to injuries caused by the rough return voyage in the Soyuz TMA-11 spacecraft. The South Korean Science Ministry said that the astronaut had a minor injury to her neck muscles and had bruised her spinal column."
  },
  {
    incidentDate: "2013-07-16",
    incident: "Aborted spacewalk after water leak in suit",
    mission: "ISS Expedition 36",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "During EVA-23, European Space Agency astronaut Luca Parmitano reported that water was steadily leaking into his helmet. Flight controllers elected to abort the EVA immediately, and Parmitano made his way back to the Quest airlock, followed by fellow astronaut Chris Cassidy. The airlock began repressurizing after a 1-hour and 32 minute spacewalk, and by this time Parmitano was having difficulty seeing, hearing, and speaking due to the amount of water in his suit. After repressurization, Expedition 36 commander Pavel Vinogradov and crewmembers Fyodor Yurchikhin and Karen Nyberg quickly removed Parmitano's helmet and soaked up the water with towels. Despite the incident, Parmitano was reported to be in good spirits and suffered no injury. By December, 2013, NASA had determined the leak to have been caused by a design flaw in the Portable Life Support System liquid coolant. The designers failed to take into account the physics of water in zero-g, which unintentionally allowed coolant water to mix with the air supply."
  },
  {
    incidentDate: "2018-08-30",
    incident: "Hole detected in station",
    mission: "Soyuz MS-09 attached to ISS Expedition 55",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "Ground controllers detected a dip in cabin pressure, which astronauts traced to a 2-millimeter hole in Soyuz MS-09, which was quickly patched up by Soyuz commander Sergey Prokopyev with epoxy."
  },
  {
    incidentDate: "2018-10-11",
    incident: "Launch booster failure; ballistic re-entry",
    mission: "Soyuz MS-10",
    location: "",
    fatalityCount: "",
    fatalityList: [],
    description:
      "The crew reported feeling weightless; mission control declared a rocket had failed. An emergency was declared and the spacecraft carrying the crew was separated from the rocket. It returned to Earth in a ballistic descent (sharper than normal angle), and the crew experienced 6.7 G during the landing. The crew did not need immediate medical care when recovered. Investigation determined the ball joint supporting one of the side boosters had been deformed during assembly; the damaged joint prevented proper separation despite proper activation of the separation motors; the booster re-contacted the core stage, inflicting further damage."
  },
  {
    incidentDate: "1930-05-17",
    incident: "",
    mission: "",
    location: "Berlin, Germany",
    fatalityCount: 1,
    fatalityList: [],
    description:
      'Max Valier, "first casualty of the modern space age", killed by rocket engine explosion.'
  },
  {
    incidentDate: "1931-02-02",
    incident: "",
    mission: "",
    location: "Mount Redoria near Milan, Italy",
    fatalityCount: 1,
    fatalityList: [],
    description:
      "A liquid fueled, 132-pound (60 kg) meteorological rocket, that was constructed by American physicist, Dr. Darwin Lyon, exploded during tests, killing a mechanic and injuring three others. Dr. Lyon was not present when the explosion occurred."
  },
  {
    incidentDate: "1933-10-10",
    incident: "",
    mission: "",
    location: "Germany",
    fatalityCount: 3,
    fatalityList: [],
    description: "Explosion in rocket manufacturing room of Reinhold Tiling"
  },
  {
    incidentDate: "1934-07-16",
    incident: "",
    mission: "A2",
    location: "Kummersdorf, Germany",
    fatalityCount: 3,
    fatalityList: [],
    description:
      "Research project under the supervision of Walter Dornberger killed Kurt Wahmke and two assistants as part of the Aggregat rocket development,  during a fuel test of a premixed hydrogen peroxide/alcohol propellant when the fuel tank exploded."
  },
  {
    incidentDate: "1960-10-24",
    incident: "",
    mission: "R-16",
    location: "Baikonur Cosmodrome, Kazakh SSR",
    fatalityCount: 78,
    fatalityList: [],
    description:
      "The Nedelin catastrophe caused by ignition of second-stage engines on the pad."
  },
  {
    incidentDate: "1963-10-24",
    incident: "",
    mission: "R-9 Desna",
    location: "Baikonur Cosmodrome, Kazakh SSR",
    fatalityCount: "7-8",
    fatalityList: [],
    description:
      'On the same day as the Nedelin catastrophe, another catastrophe took place: due to the evaporation of fuel and a short circuit, a fire took the lives of 7 or 8 people. Since then, 24 October is considered a "Black Day", and Russia has not launched rockets on that day.'
  },
  {
    incidentDate: "1964-04-14",
    incident: "",
    mission: "Delta rocket",
    location: "Cape Canaveral, US",
    fatalityCount: 3,
    fatalityList: [],
    description:
      "The third stage of a Delta rocket had just been joined to the Orbiting Solar Observatory satellite in the spin test facility building at Cape Kennedy. Eleven workers were in the room when the 205 kg (452 lb) of solid fuel in the third stage ignited. Sidney Dagle, 29; Lot D. Gabel, 51, and John Fassett, 30, were severely burned and later died of their injuries. Eight others were injured, but survived. The ignition was caused by a spark of static electricity."
  },
  {
    incidentDate: "1964-05-07",
    incident: "",
    mission: "Mail rocket",
    location: "Braunlage, West Germany",
    fatalityCount: 3,
    fatalityList: [],
    description:
      "Mail rocket built by Gerhard Zucker exploded and debris hit crowd of spectators."
  },
  {
    incidentDate: "1966-12-14",
    incident: "",
    mission: "Soyuz 7K-OK",
    location: "Baikonur Cosmodrome, USSR",
    fatalityCount: 1,
    fatalityList: [],
    description:
      "Soyuz 7K-OK No.1: Second unmanned Soyuz test flight. Launch escape system fired 27 minutes after an aborted launch causing a fire and subsequent explosion when pad workers had already returned to the launch pad."
  },
  {
    incidentDate: "1973-06-26",
    incident: "",
    mission: "Kosmos-3M launch vehicle",
    location: "Plesetsk Cosmodrome, USSR",
    fatalityCount: 9,
    fatalityList: [],
    description: "Launch explosion of Kosmos-3M rocket"
  },
  {
    incidentDate: "1980-03-18",
    incident: "",
    mission: "Vostok-2M launch vehicle",
    location: "Plesetsk Cosmodrome, USSR",
    fatalityCount: 48,
    fatalityList: [],
    description: "Explosion while fueling up a Vostok-2M rocket"
  },
  {
    incidentDate: "1990-09-07",
    incident: "",
    mission: "Titan IV",
    location: "Edwards AFB, CA United States",
    fatalityCount: 1,
    fatalityList: [],
    description:
      "A Titan IV launch vehicle solid rocket booster was being hoisted by a crane into a rocket test stand at Edwards AFB, California. The bottom section of the booster broke free, hit the ground and ignited. One person, Alan M. Quimby, 27, a civilian employee of Wyle Laboratories, was killed and 9 others were injured in the accident."
  },
  {
    incidentDate: "1991-08-09",
    incident: "",
    mission: "H-II launch vehicle",
    location: "Komaki, Aichi, Japan",
    fatalityCount: 1,
    fatalityList: [],
    description:
      "Engineer Arihiro Kanaya, 23, was conducting a high pressure endurance test on a pipe used in the first stage rocket engine of the H-2 (H-II) launch vehicle when it exploded. The explosion caused a 14 cm (5.5 in) thick door in the testing room to fall on Kanaya and fracture his skull, killing him. The accident happened at the Nagoya Guidance and Propulsion Systems Works Of Mitsubishi Heavy Industries in Komaki, Aichi, Japan."
  },
  {
    incidentDate: "1993-02-27",
    incident: "",
    mission: "Nike-Orion",
    location: "Esrange, Sweden",
    fatalityCount: 1,
    fatalityList: [],
    description:
      "Bror Thornéus, a technician from Sweden was killed when a sounding rocket ignited during testing of its ignition system at the European Sounding Rocket Range (Esrange), in northern Sweden."
  },
  {
    incidentDate: "1995-01-26",
    incident: "",
    mission: "Long March 2E",
    location: "Xichang, China",
    fatalityCount: "6+",
    fatalityList: [],
    description: "Long March rocket veered off course after launch"
  },
  {
    incidentDate: "1996-02-15",
    incident: "",
    mission: "Long March 3B",
    location: "Xichang, China",
    fatalityCount: "6-100",
    fatalityList: [],
    description:
      "Intelsat 708 Satellite, a Long March rocket, veered off course immediately after launch, crashing in the nearby village 22 seconds later, destroying 80 houses. According to official Chinese reports there were 6 fatalities and 57 injuries resulting from the incident, but other accounts estimated 100 fatalities."
  },
  {
    incidentDate: "2002-10-15",
    incident: "",
    mission: "Soyuz-U",
    location: "Plesetsk Cosmodrome, Russia",
    fatalityCount: 1,
    fatalityList: [],
    description:
      "Foton-M No.1, a Soyuz-U, exploded 29 seconds after launch, killing a soldier, Ivan Marchenko, and injuring 8 others. Fragments of the rocket started a forest fire nearby, and a Block D strap-on booster caused damage to the launchpad."
  },
  {
    incidentDate: "2003-08-22",
    incident: "",
    mission: "VLS-1",
    location: "Alcântara, Brazil",
    fatalityCount: 21,
    fatalityList: [],
    description:
      "VLS-1 V03: Explosion of an uncrewed rocket during launch preparations"
  },
  {
    incidentDate: "2007-07-26",
    incident: "",
    mission: "Engine test for SpaceShipTwo",
    location: "Mojave Spaceport, California",
    fatalityCount: 3,
    fatalityList: [],
    description:
      "Explosion during a test of rocket systems by Scaled Composites during a nitrous oxide injector test"
  },
  {
    incidentDate: "1968-05-16",
    incident: "",
    mission: "Apollo 4",
    location: "Kennedy Space Center, US",
    fatalityCount: 1,
    fatalityList: [],
    description:
      "Pad worker William B. Estes, 46, was killed while hooking up an 8-inch (20 cm) high-pressure water line to the mobile service structure on Kennedy Space Center Launch Complex 39A, which should not have been pressurized at the time. The cap blew off with 180 psi pressure, striking him in the chest."
  },
  {
    incidentDate: "1978-03-02",
    incident: "",
    mission: "",
    location: "Florida, US",
    fatalityCount: 1,
    fatalityList: [],
    description:
      'Robert E. "Champ" Murphy, was injured in an incident involving a Halon cylinder. He lost his foot and died on 8 June 1985 as a result of Halon exposure.'
  },
  {
    incidentDate: "1981-03-19",
    incident: "",
    mission: "STS-1",
    location: "Kennedy Space Center, US",
    fatalityCount: 3,
    fatalityList: [],
    description:
      "Anoxia due to nitrogen atmosphere in the aft engine compartment of Columbia during a countdown demonstration test for STS-1. Five workers were involved in the incident. John Bjornstad died at the scene; Forrest Cole went into a coma and died two weeks later, and Nick Mullon died 14 years later from complications of injuries sustained."
  },
  {
    incidentDate: "1981-05-05",
    incident: "",
    mission: "STS-2",
    location: "Kennedy Space Center, US",
    fatalityCount: 1,
    fatalityList: [],
    description:
      "Construction worker Anthony E. Hill, 22, fell more than 100 feet (30 m) from the Kennedy Space Center Launch Complex 39B service structure. Workers were preparing LC-39B for a planned September 1981 launch of the Space Shuttle Columbia."
  },
  {
    incidentDate: "1981-06-10",
    incident: "",
    mission: "",
    location:
      "Merritt Island National Wildlife Refuge / Kennedy Space Center, US",
    fatalityCount: 2,
    fatalityList: [],
    description:
      "US Fish and Wildlife Service (USFWS) firefighters Scott Maness and Beau Sauselein died while fighting a fire on refuge grounds at Kennedy Space Center. The incident prompted improvements in the USFWS wildfire program to support spaceport operations."
  },
  {
    incidentDate: "1985-12-04",
    incident: "",
    mission: "",
    location: "Vandenberg AFB, US",
    fatalityCount: 1,
    fatalityList: [],
    description:
      "Carl Reich, 49, of Lompoc, CA, an iron worker employed by Hensel Phelps Construction of Greeley, CO, fell 18 stories from the mobile service structure of the SLC-6 Space Shuttle launch complex, while bolting a platform onto the structure."
  },
  {
    incidentDate: "1986-01-30",
    incident: "",
    mission: "STS-51-L",
    location: "Titusville, FL, US",
    fatalityCount: 1,
    fatalityList: [],
    description:
      "NASA engineer Elmer Andrew Thomas, 69, of Titusville, FL suffered a heart attack while watching the Challenger disaster from a NASA viewing room. He died in the hospital two days later."
  },
  {
    incidentDate: "1988-05-04",
    incident: "",
    mission: "Space Shuttle and other solid-fuel vehicles",
    location: "Henderson, Nevada, US",
    fatalityCount: 2,
    fatalityList: [],
    description:
      "PEPCON disaster, explosion of a factory that produced ammonium perchlorate for solid-fuel rocket boosters of the Space Shuttle and other launchers."
  },
  {
    incidentDate: "1989-07-27",
    incident: "",
    mission: "Space Shuttle",
    location: "Kennedy Space Center, US",
    fatalityCount: 1,
    fatalityList: [],
    description:
      "Electrical worker Clarence E. Halley, an employee of EG&G, fell 20 feet to his death at the Vehicle Assembly Building."
  },
  {
    incidentDate: "1989-12-22",
    incident: "",
    mission: "",
    location: "Cape Canaveral, US",
    fatalityCount: 1,
    fatalityList: [],
    description:
      "A worker refurbishing the 11th level of the Cape Canaveral, Atlas Launch Complex 36B launch tower, was killed when an air hose he was using was caught by the pad elevator. The hose wrapped around the worker and pulled him into the elevator shaft, crushing him. The pad was being refurbished for commercial satellite launches by General Dynamics starting in 1990."
  },
  {
    incidentDate: "1995-05-05",
    incident: "",
    mission: "Ariane 5",
    location: "Guiana Space Centre, French Guiana",
    fatalityCount: 2,
    fatalityList: [],
    description:
      "Two technicians died from anoxia due to major nitrogen leak in confined area of umbilical mast at Ariane 5 launch area during cryogenic M1 main stage testing."
  },
  {
    incidentDate: "2001-07-08",
    incident: "",
    mission: "",
    location: "Cape Canaveral, US",
    fatalityCount: 1,
    fatalityList: [],
    description:
      "Worker disconnecting a coupling on a temporary pipe used to purge a liquid oxygen system near Launch Complex 37. Unexpected buildup of pressure caused the coupling to break loose and strike the employee in the head."
  },
  {
    incidentDate: "2001-08-24",
    incident: "",
    mission: "",
    location: "Cape Canaveral, US",
    fatalityCount: 1,
    fatalityList: [],
    description:
      'Painter Constantine "Gus" Valantasis died after a fall at Cape Canaveral Air Force Station.'
  },
  {
    incidentDate: "2001-10-01",
    incident: "",
    mission: "",
    location: "Cape Canaveral, US",
    fatalityCount: 1,
    fatalityList: [],
    description:
      "Crane operator Bill Brooks was killed in an industrial accident at Launch Complex 37."
  },
  {
    incidentDate: "2002-05-12",
    incident: "",
    mission: "Space Shuttle Buran",
    location: "Baikonur Cosmodrome, Kazakhstan",
    fatalityCount: 8,
    fatalityList: [],
    description:
      "Workers repairing the roof of the Baikonur Cosmodrome N-1/Energia vehicle assembly building died when the roof suffered a total structural collapse and crashed 80 meters (260 ft) to the ground. Buran Shuttle was destroyed."
  },
  {
    incidentDate: "2003-03-27",
    incident: "",
    mission: "STS-107",
    location: "Angelina National Forest, US",
    fatalityCount: 2,
    fatalityList: [],
    description:
      "During debris recovery efforts following the Space Shuttle Columbia disaster, Forest Service employee Charles Krenek of Lufkin, Texas and Pilot Jules F. 'Buzz' Mier, Jr. of Arizona were killed when their Bell 407 search chopper crashed in San Augustine County, Texas near the town of Broaddus. Also injured were Matt Tschacher, U.S. Forest Service, South Dakota; Richard Lange, United Space Alliance at Kennedy Space Center in Florida; and Ronnie Dale, NASA's Kennedy Space Center in Florida."
  },
  {
    incidentDate: "2003-09-13",
    incident: "",
    mission: "",
    location: "South Bay, California",
    fatalityCount: 1,
    fatalityList: [],
    description:
      'One contract worker was killed in a "small" explosion during maintenance modifications at a solid rocket fuel mixing facility.'
  },
  {
    incidentDate: "2004-02-24",
    incident: "",
    mission: "",
    location: "Satish Dhawan Space Centre, India",
    fatalityCount: 6,
    fatalityList: [],
    description:
      "After curing process of an experimental solid propellant segment weighing 14.5 tonnes, during removal of bottom plate from casting assembly, propellant within segment caught fire resulting in death of four engineers and two assistants. Three workers escaped the inferno with burn injuries. Cast Cure facility building suffered extensive damage."
  },
  {
    incidentDate: "2006-03-17",
    incident: "",
    mission: "",
    location: "Kennedy Space Center, US",
    fatalityCount: 1,
    fatalityList: [],
    description:
      "Steven Owens, a roofer employed by a subcontractor for Kennedy Space Center base operations contractor Space Gateway Company, died after falling from the roof of the Space Life Sciences Lab."
  },
  {
    incidentDate: "2010-05-05",
    incident: "",
    mission: "",
    location: "Redstone Arsenal, US",
    fatalityCount: 2,
    fatalityList: [],
    description:
      "Ammonium perchlorate explosion in a solid rocket fuel test area."
  },
  {
    incidentDate: "2011-03-14",
    incident: "",
    mission: "STS-134",
    location: "Launch Pad 39A, USA",
    fatalityCount: 1,
    fatalityList: [],
    description:
      "A person working as a swing-arm contractor fell to his death during preparations for a Space Shuttle mission. The death was later ruled a suicide."
  },
  {
    incidentDate: "2013-11-09",
    incident: "",
    mission: "",
    location: "Plesetsk, Russia",
    fatalityCount: 2,
    fatalityList: [],
    description:
      "Two workers cleaning out a propellant tank died when exposed to poisonous nitrogen tetroxide gases within the tank"
  },
  {
    incidentDate: "2017-06-14",
    incident: "",
    mission: "Progress MS-06",
    location: "Baikonur Cosmodrome, Kazakhstan",
    fatalityCount: 2,
    fatalityList: ["Yuri Khatyushin", "Vyacheslav Tyts"],
    description:
      "An ISS resupply mission, debris from the launch caused a wildfire which killed Yuri Khatyushin, who was employed to recover rocket debris. Vyacheslav Tyts was injured and died a few days afterward in hospital."
  }
];
