import React from 'react';
import { Container, Box, Typography } from '@material-ui/core';
import Timeline from './Timeline';

const App = () => {
  return (
    <Container>
      <Box my={4}>
        <Typography variant='h4' component='h1' gutterBottom>
          Launch Disasters of History
        </Typography>
        <Timeline />
      </Box>
    </Container>
  );
};

export default App;
